import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/vercel/path0/apps/landing/src/app/_/components/cookie-consent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseListener"] */ "/vercel/path0/apps/landing/src/app/_/components/supabase-listener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ColorMode"] */ "/vercel/path0/apps/landing/src/app/(standard-layout)/_/components/color-mode.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/landing/src/assets/styles/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["SupabaseProvider"] */ "/vercel/path0/apps/landing/src/shared/providers/supabase-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.4.1_next@15.1.3_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__r_h4oncxwmcttsqmyyetrh4bx6q4/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["LazyMotion","domAnimation"] */ "/vercel/path0/node_modules/.pnpm/framer-motion@11.16.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/framer-motion/dist/es/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts/inter.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.1.3_@babel+core@7.26.0_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/assets/fonts/karla.ts\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-karla-mono\",\"display\":\"swap\"}],\"variableName\":\"karla\"}");
